<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import "animate.css";

export default {};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rubik";
}

html {
  max-width: 100vw;
  overflow-x: hidden;
}
</style>
